import React from "react"

const Tag = props => {
  return (
    <div className="blog-tag header">
      <p>{props.tag}</p>
    </div>
  )
}

// helper function to maps tags passed as props
const mapTags = tags => {
  const tagList = tags.map((tag, i) =>
    tag.tag ? <Tag key={i} tag={tag.tag} /> : null
  )
  return tagList
}

const Tags = props => {
  const { tags } = props
  let tagList

  if (tags) {
    tagList = mapTags(tags)
  }
  return <div className="blog-tags-container">{tagList}</div>
}
export default Tag
export { Tags }
