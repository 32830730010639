import React, { useEffect } from "react"
import { highlightAll } from "prismjs"

const CodeSnippet = props => {
  useEffect(() => {
    highlightAll()
  })
  const { language, snippet } = props
  return (
    <pre>
      <code className={`language-${language}`}>{snippet}</code>
    </pre>
  )
}

export default CodeSnippet
