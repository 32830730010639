import React from "react"
import { Layout } from "../components/Layout"
import { Tags } from "../components/Tag"
import BlogSection from "../components/BlogSection"
import Footer from "../components/Footer"

const BlogTemplate = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { date, title, sections, image } = frontmatter
  const { tags } = frontmatter
  return (
    <Layout>
      <div className="blog-post-container">
        <article className="blog-article-container">
          <BlogSection
            sections={sections}
            date={date}
            title={title}
            snippet={""}
            language={"JavaScript"}
            image={image}
          />
          <Tags tags={tags} />
          <p className="text blog-author">{""}</p>
        </article>
      </div>
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        date
        slug
        title
        image
        tags {
          tag
        }
        sections {
          section {
            text
            image
            readTime
            header
            language
            snippet
            inputListTitle
            inputButtonLabel
          }
        }
      }
    }
  }
`

export default BlogTemplate
