import React, { useState } from "react"

const AddToList = props => {
  const { buttonLabel, setListValues } = props
  const [value, setValue] = useState("")
  const handleSubmit = () => {
    setListValues(value)
  }
  return (
    <div className="al-wrapper">
      <input value={value} onChange={e => setValue(e.target.value)}></input>
      <button
        onClick={() => {
          handleSubmit()
          setValue("")
        }}
      >
        {buttonLabel}
      </button>
    </div>
  )
}

const ListDisplay = props => {
  const { title, listValues } = props

  return (
    <div className="ld-wrapper">
      <h3>{title}</h3>
      <ul className="list-wrapper">
        {listValues.map((value, i) => (
          <li key={i}>{value}</li>
        ))}
      </ul>
    </div>
  )
}

const ListTransform = props => {
  const { values, title } = props
  const hashedValues = values.map(value => value % (values.length))
  return (
    <div className="ld-wrapper">
      <h3>{title}</h3>
      <ListDisplay listValues={hashedValues} />
    </div>
  )
}

const InputList = props => {
  const [listValues, setListValues] = useState([])
  const { title, buttonLabel } = props
  return (
    <div>
      <AddToList
        buttonLabel={buttonLabel}
        setListValues={value => setListValues([...listValues, value])}
      />
      <ListDisplay listValues={listValues} title={title} />
      <ListTransform values={listValues} title="Hashed Keys" />
    </div>
  )
}

export default InputList
export { ListDisplay }
